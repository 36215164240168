import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class AlertMessagesRepository extends BaseRepository {
    baseUrl = 'management/alert-messages';

    /**
     * Returns all available alert messages
     *
     * @returns {*}
     */
    available() {
        return this.httpClient.get(`${this.baseUrl}/available`);
    }
}
